import { createClient } from "contentful";

export function getGoals(meetingId) {
  const goalSelections = createClient({
    accessToken: `${process.env.GATSBY_SECRET_TOKEN}`,
    space: `${process.env.GATSBY_SPACE_ID}`,
    environment: `${process.env.GATSBY_ENVIRONMENT}`
  });
  return goalSelections.getEntry(meetingId);
}
