import React from 'react';
import querystring from 'query-string';
import './SectionContainer.css';
import { useIsMobile } from '../hooks/useIsMobile';
import NotFoundPage from './404';
import IncorrectRoutes from './incorrectRoutes';
import { getAdvisorTeam } from '../queries/reads/advisorTeamQuery';
import { getMeeting, getBackground } from '../queries/reads/meetingQuery';
import Applicationheader from '../components/ApplicationHeader';
import SideDotNavigator from '../components/SideDotNavigator';
import Landing from '../slides/Landing';
import Team from '../slides/Team';
import Testimonials from '../slides/Testimonials';
import WhatToBring from '../slides/WhatToBring';
import FinancialGoals from '../slides/FinancialGoals';
import Drinks from '../slides/Drinks';
import AdviceYourWay from '../slides/AdviceYourWay';
import CustomDescription from '../slides/CustomDescription';
import CustomDocumentList from '../slides/CustomDocumentList';
import CustomThreeCard from '../slides/CustomThreeCard';
import Video from '../slides/Video';
import Agenda from '../slides/Agenda';
import Summary from '../slides/Summary';
import { LoadingProvider, LoadingContext } from '../context/LoadingProvider';
import { MeetingContext } from '../context/MeetingProvider/MeetingContext';
import '../assets/fonts/index.css';

const MeetingPage = props => {
  const mobile = useIsMobile();
  const [loading, setLoading] = React.useState(true);
  const [animated, setAnimated] = React.useState(false);
  const [data, setData] = React.useState({});
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [inviteesIndex, setInviteesIndex] = React.useState(0);
  const [isVerified, setVerified] = React.useState(false);
  const { advisorId, meetingId: MeetingId } = querystring.parse(
    props.location.search
  );

  const { loadingState } = React.useContext(LoadingContext);
  const { dispatchMeetingState } = React.useContext(MeetingContext);

  React.useEffect(() => {
    window.addEventListener(
      'scroll' || 'touchend',
      e => {
        setAnimated(e.target.scrollTop >= 50);
      },
      true
    );
    async function buildPage() {
      const { advisorId, meetingId } = querystring.parse(props.location.search);
      const contentfulObject = await getAdvisorTeam(advisorId);
      const contentfulAdvisor = contentfulObject.items[0];
      let contentfulMeeting = await getMeeting(meetingId);
      if (!contentfulAdvisor.fields.showAdviceYourWay) {
        contentfulMeeting.fields.sections = contentfulMeeting.fields.sections.filter(
          _ => _.fields.slug !== 'advice-your-way'
        );
      }

      const sectionBackgrounds = contentfulMeeting.fields.sectionBackgrounds
        ? await getBackground(contentfulMeeting.fields.sectionBackgrounds)
        : { desktop: {}, mobile: {} };
      if (contentfulAdvisor === undefined) {
        setLoading(false);
        return;
      }
      const confirmedMeeting = contentfulObject.items[0].fields.advisorMeetings;
      const confirmedID = contentfulMeeting.sys.id;

      confirmedMeeting.forEach(index => {
        if (index.sys.id === confirmedID) {
          setVerified(true);
        }
      });

      const newProps = {
        contentfulAdvisor,
        contentfulMeeting,
        sectionBackgrounds
      };

      await setData(newProps);
      if (newProps.contentfulMeeting.fields.drinksSelected === undefined) {
        newProps.contentfulMeeting.fields['drinksSelected'] = {
          nodesDrinks: []
        };
      }
      if (
        newProps.contentfulMeeting.fields.financialGoalsSelected === undefined
      ) {
        newProps.contentfulMeeting.fields['financialGoalsSelected'] = {
          nodesGoals: []
        };
      }

      if (newProps.contentfulMeeting.fields.inviteesObj === undefined) {
        newProps.contentfulMeeting.fields['inviteesObj'] = [
          {
            fields: {
              email: 'preview@IPC.ca',
              firstName: 'John',
              lastName: 'Smith'
            }
          }
        ];
      }
      if (newProps.contentfulMeeting.fields.datetime === undefined) {
        newProps.contentfulMeeting.fields.datetime = new Date();
      }

      dispatchMeetingState({
        type: 'setData',
        data: newProps
      });

      setLoading(false);
    }

    buildPage();
  }, []);

  const {
    contentfulMeeting = {},
    contentfulAdvisor = {},
    sectionBackgrounds = {}
  } = data || {};
  const { fields: meetingFields = {} } = contentfulMeeting;
  const { fields: advisorFields = {} } = contentfulAdvisor;
  const {
    sections = [],
    inviteesObj = [],
    customMessage = {},
    teamMembers = [],
    drinks = [],
    testimonials = [],
    whatToBring = [],
    financialGoals = [],
    datetime = [],
    welcomeHeadline = '',
    welcomeMessage = '',
    customSlide1Title = '',
    customSlide1Subtitle = '',
    customSlide1Description = '',
    customSlide2Title = '',
    customSlide2Description = '',
    customSlide2Documents = [],
    customSlide3Title = '',
    customSlide3Description = '',
    customSlide3Documents = [],
    phoneInternet = '',
    selectedLocation = {},
    consultants,
    emailSent,
    status
  } = meetingFields;
  const {
    videoEmbed = {},
    location = {},
    website = {},
    downloadPdf = {},
    advisorType = {},
    logo = {},
    emailLogo = {},
    city = {},
    province = {},
    postalCode = {},
    teamDescription = {},
    customSlide1TitleAdvisor = '',
    customSlide1SubtitleAdvisor = '',
    customSlide1DescriptionAdvisor = '',
    customSlide2TitleAdvisor = '',
    customSlide2DescriptionAdvisor = '',
    customSlide2DocumentsAdvisor = {},
    customSlide3TitleAdvisor = '',
    customSlide3DescriptionAdvisor = '',
    customSlide3DocumentsAdvisor = {}
  } = advisorFields;

  const firstName =
    inviteesObj && inviteesObj.length > 0 && inviteesObj[0]
      ? inviteesObj[0].firstName
      : '';
  const video =
    videoEmbed.content && videoEmbed.content.length > 0
      ? videoEmbed.content[0].content[0].value
      : '';
  const note =
    customMessage && customMessage.content && customMessage.content.length > 0
      ? customMessage.content[0].content[0].value
      : '';

  const isLastInvitee = index => {
    if (inviteesIndex === inviteesObj.length - 1) {
      setInviteesIndex(0);
      setActiveSlide(index + 1);
      return true;
    }
    setInviteesIndex(inviteesIndex + 1);
    return false;
  };

  React.useEffect(() => {
    setAnimated(false);
    window.removeEventListener('scroll' || 'touchend', e => {}, true);
  }, [activeSlide]);

  const traverseBackwards = index => {
    if (inviteesIndex === 0) {
      setInviteesIndex(0);
      setActiveSlide(index - 1);
      return true;
    }
    setInviteesIndex(inviteesIndex - 1);
    return false;
  };

  return (
    <LoadingProvider>
      <React.Fragment>
        {loading === true ? (
          <NotFoundPage />
        ) : (
          <React.Fragment>
            {isVerified === false ? (
              <IncorrectRoutes advisorId={advisorId} meetingId={MeetingId} />
            ) : (
              <div style={{ height: '5px' }}>
                <Applicationheader
                  advisorType={advisorType}
                  logo={logo}
                  animated={animated}
                />
                <SideDotNavigator
                  meetingFields={meetingFields}
                  activeDotIndex={activeSlide}
                  onChange={setActiveSlide}
                  slides={meetingFields}
                  inviteesIndex={inviteesIndex}
                />

                {sections.map(({ fields: slide }, index) => {
                  const currentSlide = slide.title;
                  let nextSlide = undefined;
                  if (index < sections.length - 1) {
                    nextSlide = sections[index + 1].fields.title;
                    switch (nextSlide) {
                      case 'Custom 1 - Description':
                        nextSlide = meetingFields.customSlide1Title
                          ? meetingFields.customSlide1Title
                          : 'Custom 1 - Description';
                        break;
                      case 'Custom 2 - Document List':
                      case 'Document List':
                        nextSlide = meetingFields.customSlide2Title
                          ? meetingFields.customSlide2Title
                          : 'Document List';
                        break;
                      case 'Custom 3 - Horizontal Documents':
                        nextSlide = meetingFields.customSlide3Title
                          ? meetingFields.customSlide3Title
                          : 'Custom 3 - Horizontal Documents';
                        break;
                    }
                  }
                  switch (slide.slug) {
                    case 'landing-page':
                      if (index === activeSlide) {
                        return (
                          <Landing
                            key={index}
                            {...slide}
                            firstName={firstName}
                            invitees={inviteesObj}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            welcomeMessage={welcomeMessage}
                            welcomeHeadline={welcomeHeadline}
                            hreflocation={props.location}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            location={props.location}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'meet-our-team':
                      if (index === activeSlide) {
                        return (
                          <Team
                            key={index}
                            {...slide}
                            teamList={teamMembers}
                            teamDescription={teamDescription}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            consultants={consultants}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'what-to-drink':
                      if (index === activeSlide) {
                        return (
                          <Drinks
                            key={index + inviteesIndex}
                            nextSlide={nextSlide}
                            inviteesIndex={inviteesIndex}
                            hreflocation={props.location}
                            toNextSlide={() => {
                              isLastInvitee(index);
                            }}
                            toPreviousSlide={() => {
                              traverseBackwards(index);
                            }}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'testimonials':
                      if (index === activeSlide) {
                        return (
                          <Testimonials
                            key={index}
                            {...slide}
                            testimonialList={testimonials}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }
                    case 'advice-your-way':
                      if (index === activeSlide) {
                        return (
                          <AdviceYourWay
                            key={index}
                            {...slide}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'what-to-bring':
                      if (index === activeSlide) {
                        return (
                          <WhatToBring
                            key={index}
                            {...slide}
                            docsList={whatToBring}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'financial-goals':
                      if (index === activeSlide) {
                        return (
                          <FinancialGoals
                            key={index + inviteesIndex}
                            nextSlide={nextSlide}
                            inviteesIndex={inviteesIndex}
                            hreflocation={props.location}
                            toNextSlide={() => {
                              isLastInvitee(index);
                            }}
                            toPreviousSlide={() => {
                              traverseBackwards(index);
                            }}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'custom-description':
                      if (index === activeSlide) {
                        return (
                          <CustomDescription
                            key={index}
                            {...slide}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            customSlide1Title={
                              customSlide1Title || customSlide1TitleAdvisor
                            }
                            customSlide1Subtitle={
                              customSlide1Subtitle ||
                              customSlide1SubtitleAdvisor
                            }
                            customSlide1Description={
                              customSlide1Description ||
                              customSlide1DescriptionAdvisor
                            }
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'custom-documents-list':
                      if (index === activeSlide) {
                        return (
                          <CustomDocumentList
                            key={index}
                            {...slide}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            customSlide2Title={
                              customSlide2Title || customSlide2TitleAdvisor
                            }
                            customSlide2Description={
                              customSlide2Description ||
                              customSlide2DescriptionAdvisor
                            }
                            customSlide2Documents={
                              customSlide2Documents === undefined ||
                              (!customSlide2Documents.length &&
                                customSlide2DocumentsAdvisor !== undefined)
                                ? [customSlide2DocumentsAdvisor]
                                : customSlide2Documents
                            }
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'custom-3-cards':
                      if (index === activeSlide) {
                        return (
                          <CustomThreeCard
                            key={index}
                            {...slide}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            customSlide3Title={
                              customSlide3Title || customSlide3TitleAdvisor
                            }
                            customSlide3Description={
                              customSlide3Description ||
                              customSlide3DescriptionAdvisor
                            }
                            customSlide3Documents={
                              customSlide3Documents === undefined ||
                              (!customSlide3Documents.length &&
                                customSlide3DocumentsAdvisor !== undefined)
                                ? [customSlide3DocumentsAdvisor]
                                : customSlide3Documents
                            }
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'video':
                      if (index === activeSlide) {
                        return (
                          <Video
                            key={index}
                            {...slide}
                            {...props}
                            currentSlide={currentSlide}
                            nextSlide={nextSlide}
                            video={video}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            sectionBackgrounds={sectionBackgrounds}
                            mobile={mobile}
                          />
                        );
                      } else {
                        return null;
                      }

                    case 'summary':
                      if (index === activeSlide && !loadingState.IsLoading) {
                        return (
                          <Summary
                            key={index}
                            {...slide}
                            advisorSpecifics={advisorFields}
                            firstName={firstName}
                            note={note}
                            invitees={inviteesObj}
                            location={location}
                            datetime={datetime}
                            website={website}
                            downloadPdf={downloadPdf}
                            province={province}
                            city={city}
                            postalCode={postalCode}
                            advisorType={advisorType}
                            emailLogo={emailLogo}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                            hreflocation={props.location}
                            sectionBackgrounds={sectionBackgrounds}
                            phoneInternet={phoneInternet}
                            selectedLocation={selectedLocation}
                            mobile={mobile}
                            isEmailSent={emailSent}
                            meetingStatus={status}
                          />
                        );
                      }

                    case 'agenda':
                      if (index === activeSlide && !loadingState.IsLoading) {
                        return (
                          <Agenda
                            key={index}
                            nextSlide={nextSlide}
                            toNextSlide={() => setActiveSlide(index + 1)}
                            toPreviousSlide={() => setActiveSlide(index - 1)}
                          />
                        );
                      } else {
                        return null;
                      }

                    default:
                      return null;
                  }
                })}
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </LoadingProvider>
  );
};

export default MeetingPage;
