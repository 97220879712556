import React from "react";
import {
  FadeInBackground,
  FadeIn,
  PanUpButton,
  PanUpFast,
} from "../animations";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";
import flexibility from "./flexibility.svg";
import person from "./person.svg";
import bubbls from "./bubbls.svg";

import "./adviceYourWay.css";

const AdviceYourWay = ({
  title,
  sectionBackgrounds,
  nextSlide,
  toNextSlide,
  toPreviousSlide,
  slug,
  mobile,
  ...props
}) => {
  let backgroundImage = mobile
    ? sectionBackgrounds.mobile[slug]
    : sectionBackgrounds.desktop[slug];
  if (!backgroundImage) {
    backgroundImage = props.backgroundImage.fields.file.url;
  }
  useScrollToElement("pageTop");
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <FadeIn>
          <section className="slide-overflow fade-6 kenBurns" id="video">
            <div
              className="content"
              style={{
                overflow: "auto",
                backgroundPosition: `center`,
                height: `100vh`,
                backgroundImage: `url(${backgroundImage})`,
              }}
            >
              <div className="container" style={{ height: "100vh" }}>
                <div className="wrap">
                  <div className="fix-12-12 white-text compensate-margin-top">
                    <div
                      className="test fromCenter margin-top-6 "
                      style={{
                        width: "100%",
                        margin: "0 auto",
                      }}
                    >
                      <div style={{ marginBottom: "50px" }}>
                        <h1 className="advice-title">
                          {" "}
                          Our{" "}
                          <span
                            style={{
                              color: "#75a556",
                            }}
                          >
                            Difference
                          </span>{" "}
                        </h1>{" "}
                        <p className="advice-desc">
                          From market shifts to major life milestones, we will
                          meet you where you are - in just the way you choose -
                          and work with you to get where you want to go.
                        </p>
                        <p className="advice-desc-sub">
                          That’s advice, your way.
                        </p>
                      </div>
                      <div className="div-blocks">
                        <div className="advice-block">
                          <img src={flexibility} />

                          <div className="advisor-way-p">
                            <p style={{ marginBottom: 0 }}>Flexibility</p>
                            Juggling schedules and trying to get family together
                            in one place or getting financial advice for your
                            wealth management questions shouldn’t be stressful.
                            That’s why we’ll meet you on your terms, be it
                            one-on-one online, in-person, or on a group video
                            call with everyone in your family – unrestricted by
                            regions and time zones.
                          </div>
                        </div>

                        <div className="advice-block">
                          <img src={person} width="70px" height="70px" />
                          <div className="advisor-way-p">
                            <p style={{ marginBottom: 0 }}>
                              Personalized Advice
                            </p>
                            Planning is personal. We provide different levels of
                            service that you can choose from – so you get what’s
                            most effective for you. We will help you find your
                            preferred approach – whether you want to plan for a
                            specific target goal, comprehensive wealth
                            management advice, or anything in between.
                          </div>
                        </div>

                        <div className="advice-block">
                          <img src={bubbls} />
                          <div className="advisor-way-p">
                            <p style={{ marginBottom: 0 }}>
                              Engaged Relationships
                            </p>
                            Communication is integral to how we work with you.
                            We will review, adjust and adapt your plan based on
                            your engagement preferences, and we’ll stay
                            connected with you – through your channels of
                            choice.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <PanUpButton>
                    <MobileButtonWrapper>
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                      <BackButton toPreviousSlide={toPreviousSlide} />
                    </MobileButtonWrapper>

                    <DesktopButtonWrapper>
                      <BackButton toPreviousSlide={toPreviousSlide} />
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                    </DesktopButtonWrapper>
                  </PanUpButton>
                </div>
              </div>
            </div>
          </section>
        </FadeIn>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default AdviceYourWay;
