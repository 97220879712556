import React from 'react';
import './Drinks.css';
import arrow from '../../assets/svg/arrow-down.svg';
import styled from 'styled-components';
import '../layout.css';
import Image from '../../assets/img/buttonAssets/back-button-slides.png';
import querystring from 'query-string';
import ReactGA from 'react-ga';
import {
  PanLeftFast,
  PanRightFast,
  FadeIn,
  PanUpButton,
  FadeInBackground
} from '../animations';
import { useScrollToElement } from '../../assets/js/useScrollToElement';
import { getDrinks } from '../../queries/reads/drinkSelectionOverride';
import { getMeetingId } from '../../queries/helpers/getMeetingId';
import { LoadingContext } from '../../context/LoadingProvider/LoadingContext';
import MobileButtonWrapper from '../../components/MobileButtonWrapper';
import DesktopButtonWrapper from '../../components/DesktopButtonWrapper';

import { writeDrinks } from '../../queries/writes/writeDrinks';
import { MeetingContext } from '../../context/MeetingProvider/MeetingContext';

ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS);

const DrinksWrapper = styled.div`
  @media screen and (min-width: 601px) {
    min-height: 500px;
  }
`;

const Drinks = ({
  nextSlide,
  toNextSlide,
  toPreviousSlide,
  inviteesIndex,
  hreflocation
}) => {
  const [drinkSelection, setDrinkSelection] = React.useState([]);
  const [drink, setCurrentDrink] = React.useState(null);
  const [image, setImage] = React.useState(null);
  const { dispatchLoadingState } = React.useContext(LoadingContext);
  const [nodesDrinks, setNode1] = React.useState({});
  const { meetingState, dispatchMeetingState } = React.useContext(
    MeetingContext
  );
  const [localFields, setLocalFields] = React.useState({});
  const drinks = meetingState.contentfulMeeting.fields.drinks;
  const inviteesObj = meetingState.contentfulMeeting.fields.inviteesObj;
  const firstName = inviteesObj[inviteesIndex].firstName;

  React.useEffect(() => {
    setDrinkSelection(drink);
  }, [drink]);

  React.useEffect(() => {
    if (meetingState.contentfulMeeting.fields) {
      const result = meetingState.contentfulMeeting.fields.sections.filter(
        slide => slide.fields.slug === 'what-to-drink'
      );
      setLocalFields(result[0].fields);
    }
  }, [meetingState]);

  const WritetoDb = drink => {
    const { preview } = querystring.parse(hreflocation.search);
    if (preview === 'true') {
    } else {
      const ID = getMeetingId();

      dispatchLoadingState(true);
      writeDrinks(ID, nodesDrinks);

      const updatedMeeting = { ...meetingState.contentfulMeeting };

      if (!updatedMeeting.fields.drinksSelected.nodesDrinks[firstName]) {
        updatedMeeting.fields.drinksSelected.nodesDrinks[firstName] = [drink];
      } else {
        updatedMeeting.fields.drinksSelected.nodesDrinks[firstName] = [drink];
      }

      dispatchMeetingState({
        type: 'setMeeting',
        data: updatedMeeting
      });

      dispatchLoadingState(false);
    }
  };

  //Used to apply css of "selected" drink and the correllating image from contentful  * changes on Oct 30 * also writes to DB on click of drink tiles
  const selectDrink = (drink, test) => {
    setCurrentDrink(drinks[drink].fields.title);
    setDrinkSelection(drinks[drink].fields.title, firstName);
    setImage(drinks[drink]);
    WritetoDb(test);
  };

  //constructs the individual node for each invitee in a format that can be written to DB
  const capturedSelectionDrink = (item, person) => {
    const collectedNodes = nodesDrinks;
    const node = [item];
    collectedNodes[person] = node;
    setNode1(collectedNodes);
  };

  //Used to retrieve drink choices if they are entering a meeting for a second time so we can repopulate selects
  React.useEffect(() => {
    async function getDrinkChoices() {
      const ID = getMeetingId();
      const incomingMeetingObject = await getDrinks(ID);
      if (incomingMeetingObject.fields.drinksSelected === undefined) {
        setDrinkSelection([]);
      } else {
        let incomingSelections =
          incomingMeetingObject.fields.drinksSelected.nodesDrinks;
        if (Object.keys(incomingSelections).length === 0) {
          setDrinkSelection([]);
        } else {
          let personsIndex = Object.keys(incomingSelections);
          if (inviteesIndex >= personsIndex.length) {
            setDrinkSelection([]);
          } else {
            let chosenDrink = '';
            personsIndex.forEach(function(person) {
              if (person === firstName) {
                chosenDrink = incomingSelections[person][0];
                setDrinkSelection(incomingSelections[person][0]);
                setImage(drinks[retrievedDrinkIndex]);
              }
            });
            const retrievedDrinkIndex = drinks.findIndex(
              drink => drink.fields.title === chosenDrink
            );
            setImage(drinks[retrievedDrinkIndex]);
          }
        }
      }
    }
    getDrinkChoices();
  }, []);

  React.useEffect(() => {
    return () => {
      const { preview } = querystring.parse(hreflocation.search);
      if (preview !== 'true') {
        // Google analytics event to track the drink choices
        if (drink !== null) {
          ReactGA.event({
            category: 'Drink',
            action: `Client selected a drink`
          });
        }
      }
    };
  }, [drink]);

  //Used to display the next invitees name at the bottom bar
  let nameArray = [];
  inviteesObj.forEach(function(person, index) {
    if (index !== 0) {
      let string = person.firstName;
      nameArray.push(`${string}'s Drink Selection`);
    }
  });

  useScrollToElement('pageTop');
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id='pageTop'></div>
        <section
          id='what-to-drink'
          className='slide-overflow fade-3 kenBurns'
          data-title='Drink'
        >
          <div
            className='content'
            style={{
              overflow: 'auto',
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                meetingState.contentfulMeeting.fields.sectionBackgrounds[
                  'what-to-drink'
                ]
              })`
            }}
          >
            <div className='container' style={{ height: '100vh' }}>
              <div className='wrap'>
                <div className='fix-12-12 compensate-margin-top'>
                  <ul className='grid'>
                    <li className='col-6-12 left cell-33'>
                      <PanLeftFast>
                        <h1
                          className='ae-2 fromLeft calson-44'
                          style={{ textAlign: 'left' }}
                        >
                          {localFields.title}
                        </h1>

                        <p
                          className='ae-2 fromLeft'
                          style={{ textAlign: 'left' }}
                        >
                          <span className='normal catamaran-24-center'>
                            <u>
                              <b>{firstName}</b>
                            </u>
                            {', '}
                            {localFields.description}
                          </span>
                        </p>
                      </PanLeftFast>

                      <FadeIn>
                        <DrinksWrapper>
                          <ul
                            className='tabs controller uppercase bold ae-3 fromCenter catamaran-16-left
                          '
                          >
                            {drinks.map((drink, index) => (
                              <li
                                className={
                                  drink.fields.title === drinkSelection
                                    ? 'selected'
                                    : ''
                                }
                                key={'drink' + index}
                                onClick={() => {
                                  selectDrink(index, drink.fields.title);

                                  capturedSelectionDrink(
                                    drink.fields.title,
                                    inviteesObj[inviteesIndex].firstName
                                  );
                                }}
                              >
                                {drink.fields.title}
                              </li>
                            ))}
                          </ul>
                        </DrinksWrapper>
                      </FadeIn>
                    </li>
                    <li className='col-6-12 left ae-4 fromRight'>
                      <ul className='slider animated' data-slider-id={31}>
                        <li className='selected fromRight'>
                          {image && image.fields.image !== undefined ? (
                            <PanRightFast>
                              <img
                                className='ipad-33'
                                data-action='zoom'
                                src={image.fields.image.fields.file.url}
                                alt='placeholder'
                              />
                            </PanRightFast>
                          ) : (
                            ''
                          )}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <PanUpButton>
                  <MobileButtonWrapper>
                    <div
                      className='button nextSlide round white stroke crop margin-top-5 ae-10 catamaran-button'
                      onClick={() => toNextSlide()}
                    >
                      {nameArray[inviteesIndex] !== undefined
                        ? nameArray[inviteesIndex].toUpperCase()
                        : nextSlide.toUpperCase()}

                      <i className='material-icons'></i>
                      <img className='arrow' src={arrow} alt='test' />
                    </div>
                    <div
                      style={{
                        backgroundImage: `url(${Image})`,
                        backgroundSize: ' cover',
                        width: '60px',
                        height: '60px',
                        marginRight: '12px'
                      }}
                      className='button nextSlide round margin-top-5 ae-10 catamaran-button'
                      onClick={() => {
                        toPreviousSlide();
                      }}
                    ></div>
                  </MobileButtonWrapper>
                  <DesktopButtonWrapper>
                    <div
                      style={{
                        backgroundImage: `url(${Image})`,
                        backgroundSize: ' cover',
                        width: '60px',
                        height: '60px',
                        marginRight: '12px'
                      }}
                      className='button nextSlide round margin-top-5 ae-10 catamaran-button'
                      onClick={() => {
                        toPreviousSlide();
                      }}
                    ></div>
                    <div
                      className='button nextSlide round white stroke crop margin-top-5 ae-10 catamaran-button'
                      onClick={() => toNextSlide()}
                    >
                      {nameArray[inviteesIndex] !== undefined
                        ? nameArray[inviteesIndex].toUpperCase()
                        : nextSlide.toUpperCase()}

                      <i className='material-icons'></i>
                      <img className='arrow' src={arrow} alt='test' />
                    </div>
                  </DesktopButtonWrapper>
                </PanUpButton>
              </div>
            </div>
          </div>
          <div />
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default Drinks;
