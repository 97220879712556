import React from "react";
import "../../assets/css/arrow-icon.css";
import { FadeInBackground } from "../animations";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import { PanUpButton } from "../animations";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";
import downloadIcon from "../../assets/img/downloadIcon/download-circle-icon_2x.png";

const CustomThreeCard = ({
  title,
  description,
  nextSlide,
  mobile,

  toPreviousSlide,
  toNextSlide,
  customSlide3Title = "",
  customSlide3Description = "",
  customSlide3Documents,
  sectionBackgrounds,
  slug,
}) => {
  useScrollToElement("pageTop");

  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <section
          className="slide-overflow  fade-6 kenBurns "
          id="custom-3-cards"
        >
          <div
            className="content"
            style={{
              overflow: "auto",
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                mobile
                  ? sectionBackgrounds.mobile[slug]
                  : sectionBackgrounds.desktop[slug]
              })`,
            }}
          >
            <div className="container" style={{ height: "100vh" }}>
              <div className="wrap ">
                <div className="fix-9-12 toCenter compensate-margin-top">
                  <h1 className="ae-1 calson-44" style={{ color: `white` }}>
                    {customSlide3Title ? customSlide3Title : title}
                  </h1>
                  <p className="cropBottom light ae-2 catamaran-20-center">
                    <span className="opacity-8 white-text">
                      {customSlide3Description &&
                      customSlide3Description !== undefined
                        ? customSlide3Description.content[0].content[0].value
                        : description}
                    </span>
                  </p>
                </div>
                <div className="fix-11-12">
                  <ul className="flex grid-47">
                    {customSlide3Documents instanceof Array
                      ? customSlide3Documents.map((companyDocument, index) => {
                          if (Object.keys(companyDocument).length === 0) {
                            return null;
                          }
                          return (
                            <li
                              key={index}
                              className="col-4-12 margin-top-5 catamaran-22-center"
                              style={{
                                color: `white`,
                              }}
                            >
                              <img
                                src={
                                  companyDocument.fields.icon.fields.file.url
                                }
                                height="50px"
                                alt="temporary"
                              />

                              <h3 className="margin-top-1 ae-4">
                                {companyDocument.fields.title}
                              </h3>
                              <div className="margin-top-1 ae-5">
                                <p className="tiny opacity-6 catamaran-18-center">
                                  {companyDocument.fields.description &&
                                    companyDocument.fields.description
                                      .content[0] &&
                                    companyDocument.fields.description
                                      .content[0].content[0].value}
                                </p>

                                <a
                                  className="button round white crop margin-top-5 ae-10"
                                  href={
                                    companyDocument.fields.download.fields.file
                                      .url
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignContent: "center",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={downloadIcon}
                                      style={
                                        ({ width: "25px" }, { height: "25px" })
                                      }
                                      alt="icon"
                                    />{" "}
                                    PDF DOWNLOAD
                                  </div>
                                </a>
                              </div>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
                <PanUpButton>
                  <MobileButtonWrapper>
                    <NextSlideButton
                      nextSlide={nextSlide}
                      toNextSlide={toNextSlide}
                    />
                    <BackButton toPreviousSlide={toPreviousSlide} />
                  </MobileButtonWrapper>

                  <DesktopButtonWrapper>
                    <BackButton toPreviousSlide={toPreviousSlide} />
                    <NextSlideButton
                      nextSlide={nextSlide}
                      toNextSlide={toNextSlide}
                    />
                  </DesktopButtonWrapper>
                </PanUpButton>
              </div>
            </div>
          </div>
          <div />
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default CustomThreeCard;
