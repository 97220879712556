const contentful = require("contentful-management");

export async function writeGoals(meetingId, nodesGoals) {
  let goalsState = {
    nodesGoals
  };

  const LOCAL = "en-US";
  const client = contentful.createClient({
    accessToken: `${process.env.GATSBY_SECRET_TOKEN_STORE_ADMIN}`
  });

  const space = await client.getSpace(`${process.env.GATSBY_SPACE_ID}`);
  const env = await space.getEnvironment(`${process.env.GATSBY_ENVIRONMENT}`);
  const meeting = await env.getEntry(meetingId);

  if (meeting.fields.financialGoalsSelected === undefined) {
    meeting.fields.financialGoalsSelected = { [LOCAL]: { nodesGoals: {} } };
  }

  Object.keys(goalsState.nodesGoals).forEach(name => {
    if (name in meeting.fields.financialGoalsSelected[LOCAL]) {
      meeting.fields.financialGoalsSelected[LOCAL] = {
        nodesGoals: {
          [name]: goalsState.nodesGoals[name]
        }
      };
    } else {
      meeting.fields.financialGoalsSelected[LOCAL].nodesGoals[name] =
        goalsState.nodesGoals[name];
    }
  });

  const finalGoalsSelections = await meeting.update();
  return await finalGoalsSelections.publish();
}
