const contentful = require("contentful-management");

export async function writeDrinks(meetingId, nodesDrinks) {
  let drinkState = {
    nodesDrinks
  };

  const LOCAL = "en-US";
  const client = contentful.createClient({
    accessToken: `${process.env.GATSBY_SECRET_TOKEN_STORE_ADMIN}`
  });

  const space = await client.getSpace(`${process.env.GATSBY_SPACE_ID}`);
  const env = await space.getEnvironment(`${process.env.GATSBY_ENVIRONMENT}`);
  const meeting = await env.getEntry(meetingId);

  if (meeting.fields.drinksSelected === undefined) {
    meeting.fields.drinksSelected = { [LOCAL]: { nodesDrinks: {} } };
  }

  Object.keys(drinkState.nodesDrinks).forEach(name => {
    if (name in meeting.fields.drinksSelected[LOCAL]) {
      meeting.fields.drinksSelected[LOCAL] = {
        nodesDrinks: {
          [name]: drinkState.nodesDrinks[name]
        }
      };
    } else {
      meeting.fields.drinksSelected[LOCAL].nodesDrinks[name] =
        drinkState.nodesDrinks[name];
    }
  });

  const finalDrinkSelections = await meeting.update();
  return await finalDrinkSelections.publish();
}
