import React from "react";
import "./ApplicationHeader.css";
import defaultimage from "../../assets/img/videoAssets/defaultVideo.jpg";
import ipcMFDA from "../../assets/img/headerAssets/ipcMFDA.png";
import ipcIIROC from "../../assets/img/headerAssets/ipcIIROC.png";
import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";
import { slideOutUp } from "react-animations";

import { isSafari } from "react-device-detect";

const Logo1 = styled.div`
  height: 60px;
  width: 200px;
  background-color: transparent;
  background-position: center;
  background-image: url(${props => props.logo});
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px;
  @media screen and (max-width: 600px) {
    max-width: 45%;
  }
`;

const ApplicationHeader = ({ advisorType, logo , animated }) => {
  const fadeout = keyframes`${slideOutUp}`;
  const fadeAnimationDown = keyframes`${fadeIn}`;
  const HeaderContainer = styled.div`
    animation: 2s ${animated ? fadeout : fadeAnimationDown};
    position: fixed;
    z-index: 200;
    top: 0;
    animation-fill-mode: forwards;
    background-color: transparent;
    top: 0;
    left: 0;
    right: 0;
    @media screen and (max-width: 600px) {
       {
        content-align: center;
      }
    }
  `;

  const Label = styled.div`
    animation-fill-mode: backwards;
  `;


  let logo1 = "";

  switch (advisorType) {
    case "MFDA":
      logo1 = ipcMFDA;
      break;
    case "IIROC":
      logo1 = ipcIIROC;
      break;
    case "OBA":
      logo1 =
        logo && logo.fields && logo.fields.file
          ? logo.fields.file.url
          : defaultimage;
      break;
    default:
      break;
  }

  return (
    <React.Fragment>
      <HeaderContainer>
        {isSafari === true ? (
          <div
            className="buffer-banner"
            style={{ backgroundColor: "#232b2b", opacity: "0.95" }}
          ></div>
        ) : (
          <div className="buffer-banner"></div>
        )}

        {isSafari === true ? (
          <div
            className="top-bar-holding-logo"
            style={{ backgroundColor: "#232b2b", opacity: "0.95" }}
          >
            <div className="padding-box"></div>
            <Logo1
              style={{
                backgroundImage: `url(${logo1})`
              }}
            />
            <div className="padding-box"></div>
            {["IIROC", "MFDA"].includes(advisorType) ? (
              <div
                className="logo2"
                style={{
                  backgroundImage:
                    logo && logo.fields && logo.fields.file
                      ? `url(${logo.fields.file.url})`
                      : `url(${defaultimage} opacity: "0")`
                }}
              ></div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <Label>
            <div className="top-bar-holding-logo">
              <div className="padding-box"></div>

              <Logo1
                style={{
                  backgroundImage: `url(${logo1})`
                }}
              />
              <div className="padding-box"></div>
              {["IIROC", "MFDA"].includes(advisorType) ? (
                <div
                  className="logo2"
                  style={{
                    backgroundImage:
                      logo && logo.fields && logo.fields.file
                        ? `url(${logo.fields.file.url})`
                        : `url(${defaultimage} opacity: "0")`
                  }}
                ></div>
              ) : (
                <div></div>
              )}
            </div>
          </Label>
        )}
      </HeaderContainer>
    </React.Fragment>
  );
};

export default ApplicationHeader;
