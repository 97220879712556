import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import back from "../../assets/img/summaryAssets/test.png";
import Other from "../../assets/img/summaryAssets/other.svg";
import Phone from "../../assets/img/summaryAssets/phone.svg";

export const MapContainer = ({ latitude, longitude, google, location }) => {
  const showMap = location !== "others" && location !== "phone";
  const backgroundSh = {
    backgroundImage: `url(${back})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    width: "340px",
    height: "700px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "47px",
  };

  const wrapped = {
    minWidth: "313px",
    minHeight: "561px",
    position: "relative",
    marginTop: "-24px",
    marginLeft: "2px",
  };

  return (
    <div style={backgroundSh}>
      <div style={wrapped}>
        {showMap && (
          <Map
            google={google}
            zoom={15}
            initialCenter={{ lat: latitude, lng: longitude }}
          >
            <Marker
              name={"test"}
              position={{ lat: latitude, lng: longitude }}
            />
          </Map>
        )}

        {!showMap && location === "phone" && (
          <img src={Phone} style={{ marginTop: "20px", marginRight: "1px" }} />
        )}
        {!showMap && location === "others" && (
          <img src={Other} style={{ marginTop: "20px", marginRight: "1px" }} />
        )}
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
})(MapContainer);
