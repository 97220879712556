import React from "react";
import Image from "../../assets/img/buttonAssets/back-button-slides.png";

const BackButton = ({ toPreviousSlide = () => {} }) => {
  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: `url(${Image})`,
          backgroundSize: " cover",
          width: "60px",
          height: "60px",
          marginRight: "12px"
        }}
        className="button nextSlide round  margin-top-5 ae-10 catamaran-button"
        onClick={() => toPreviousSlide()}
      ></div>
    </React.Fragment>
  );
};

export default BackButton;
