import React from "react";
import "../../assets/css/custom.css";
import "../../assets/css/slides.css";

const SideDotNavigator = ({
  activeDotIndex,
  onChange,
  slides,
  meetingFields,
}) => {
  const handleScroll = (event) => {
    const slug = event.target.getAttribute("slug");
    const index = event.target.getAttribute("index");
    scrollhandler(slug);
    onChange(parseInt(index));
  };

  const scrollhandler = (slug) => {
    const element = document.getElementById(slug);
    if (!element) {
      return false;
    }
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };


  const getCards = () => {
    return slides.sections.map((slide, index) => {
      if (slide.fields.title === "Custom 1 - Description") {
        return (
          <li
            key={index}
            index={index}
            slug={slide.slug}
            data-title={meetingFields.customSlide1Title ? meetingFields.customSlide1Title : "Custom 1 - Description" }
            className={activeDotIndex === index ? "selected" : ""}
            onClick={(event) => {
              handleScroll(event);
            }}
          />
        );
      }
      if (slide.fields.title === "Custom 3 - Horizontal Documents") {
        return (
          <li
            key={index}
            index={index}
            slug={slide.slug}
            data-title={meetingFields.customSlide3Title ?meetingFields.customSlide3Title  :"Custom 3 - Horizontal Documents" }
            className={activeDotIndex === index ? "selected" : ""}
            onClick={(event) => {
              handleScroll(event);
            }}
          />
        );
      }
      if (slide.fields.title === "Custom 2 - Document List" || slide.fields.title ==="Document List") {
        return (
          <li
            key={index}
            index={index}
            slug={slide.slug}
            data-title={meetingFields.customSlide2Title ? meetingFields.customSlide2Title :"Document List"}
            className={activeDotIndex === index ? "selected" : ""}
            onClick={(event) => {
              handleScroll(event);
            }}
          />
        );
      }
      return (
        <li
          key={index}
          index={index}
          slug={slide.slug}
          data-title={slide.fields.title}
          className={activeDotIndex === index ? "selected" : ""}
          onClick={(event) => {
            handleScroll(event);
          }}
        />
      );
    });
  };

  // getCards();

  return (
    <nav className="side ">
      <div className="navigation " style={{ fontFamily: "catamaran" }}>
        <ul>{getCards()}</ul>
      </div>
    </nav>
  );
};

export default SideDotNavigator;
