import React from "react";
import arrow from "../../assets/svg/arrow-down.svg";

const NextSlidebutton = ({ nextSlide, toNextSlide = () => {} }) => {
  return (
    <React.Fragment>
      <div
      style = {{}}
        className="button nextSlide round stroke white  margin-top-5 ae-10 catamaran-button"
        onClick={() => toNextSlide()}
      >
        {nextSlide.toUpperCase()}
        <i className="material-icons"></i>
        <img className="arrow" src={arrow} alt="test" />
      </div>
    </React.Fragment>
  );
};

export default NextSlidebutton;
