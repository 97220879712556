import React from "react";
import Proptypes from "prop-types";
import "../layout.css";
import styled from "styled-components";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import { PanRightFast, PanUpButton, FadeInBackground } from "../animations";
import { updateMeetingStatus } from "../../queries/writes/writeMeetingStatus";
import querystring from "query-string";
import NextSlideButton from "../../components/NextSlideButton";
import ReactGA from "react-ga";

const Landing = ({
  title,
  subtitle,
  description,
  firstName,
  invitees,
  sectionBackgrounds,
  buttonText,
  welcomeHeadline,
  welcomeMessage,
  hreflocation,
  slug,
  nextSlide,
  toNextSlide,
  location,
  mobile,
  ...props
}) => {
  React.useEffect(() => {
    async function updatemeeting() {
      const { meetingId } = querystring.parse(location.search);
      const { preview } = querystring.parse(location.search);
      if (preview !== "true") {
        await updateMeetingStatus(meetingId, "Opened");
      }
    }
    updatemeeting();
  }, []);
  useScrollToElement("pageTop");
  let inviteesString = "";
  invitees.forEach(function(person) {
    let string = person.firstName;
    inviteesString += `${string}, `;
  });

  React.useEffect(() => {
    return () => {
      const { preview } = querystring.parse(hreflocation.search);
      if (preview !== "true") {
        // Google analytics event to track the drink choices
        ReactGA.event({
          category: "Entered Meeting",
          action: `Client entered their meeting`
        });
      }
    };
  }, []);

  const BackgroundImage = styled.div`
    min-height: 100vh;
    background-position: center;
    background-image: url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (max-width: 601px) {
      background-position: 60%;
    }
  `;

  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <section
          id="landing-page"
          className="slide-overflow fade-1 kenBurns fromRight white-text"
          data-title="Welcome"
        >
          <div className="content">
            <BackgroundImage image={mobile ? sectionBackgrounds.mobile[slug] :sectionBackgrounds.desktop[slug] }>
              <div
                className="container container-div"
                style={{ minHeight: `100vh` }}
              >
                <div className="wrap">
                  <div className="fix-12-12 compensate-margin-top">
                    <PanRightFast>
                      <div className="fix-6-12 left toLeft">
                        <h1 className="ae-1 calson-44">
                          {inviteesString}
                          {welcomeHeadline ? welcomeHeadline : subtitle}
                        </h1>
                        <p
                          className="ae-2 normal catamaran-20-left"
                          style={{ marginBottom: "0px" }}
                        >
                          <span>
                            {welcomeMessage && welcomeMessage !== undefined
                              ? welcomeMessage.content[0].content[0].value
                              : description}
                          </span>
                        </p>
                        <PanUpButton>
                          <NextSlideButton
                            nextSlide={nextSlide}
                            toNextSlide={toNextSlide}
                          />
                        </PanUpButton>
                      </div>
                    </PanRightFast>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

Landing.prototype = {
  data: Proptypes.object.isRequired
};

export default Landing;
