import React from "react";

export function useScrollToElement(elementID) {
  React.useEffect(() => {
    const foundElement = document.getElementById(elementID);
    if (foundElement) {
      foundElement.scrollIntoView();
    }
  }, []);
}
