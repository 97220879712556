import React from "react";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import "../layout.css";
import {
  PanUpFast,
  PanUpButton,
  SequentialRender,
  FadeInBackground,
} from "../animations";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";
import styled from "styled-components";

const MobileSpacer = styled.div`
  @media screen and (max-width: 602px) {
    min-height: 100px;
    overflow: hidden;
    padding-bottom: 10px;
  }
  @media screen and (min-width: 601px) {
    height: 260px;
  }
`;

const WhatToBring = ({
  sectionBackgrounds,
  slug,
  title,
  docsList,
  nextSlide,
  description,
  toNextSlide,
  toPreviousSlide,
  mobile,
}) => {
  useScrollToElement("pageTop");
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <section
          id="what-to-bring"
          className="slide-overflow fade-1 kenBurns"
          data-title="What to Bring"
        >
          <div
            className="content"
            style={{
              overflow: "auto",
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                mobile
                  ? sectionBackgrounds.mobile[slug]
                  : sectionBackgrounds.desktop[slug]
              })`,
            }}
          >
            <div className="container">
              <div className="wrap">
                <PanUpFast>
                  <div className="fix-10-12 toCenter compensate-margin-top">
                    <h1 className="ae-1 calson-44 white-text ">{title}</h1>
                    <h3 className="fix-10-12 big light ae-2 grey catamaran-20-center">
                      <span>{description}</span>
                    </h3>
                  </div>
                </PanUpFast>

                <div className="fix-12-12">
                  <ul className="flex grid-47 margin-top-6  ">
                    {docsList.map((financialDoc, index) => {
                      return (
                        <SequentialRender number={index}>
                          <li
                            key={index}
                            className="col-3-12 col-tablet-1-2 col-phablet-1-1"
                          >
                            <MobileSpacer>
                              <div
                                className="fix-4-12 ae-3"
                                style={{
                                  paddingRight: "15px",
                                  paddingLeft: "15px",
                                }}
                              >
                                <div style={{ height: "60px" }}>
                                  <img
                                    src={
                                      financialDoc.fields.icon.fields.file.url
                                    }
                                    style={{ height: "50px", maxWidth: "55px" }}
                                    alt="temporary"
                                  />
                                </div>
                                <div>
                                  <h2
                                    className="small catamaran-22-center"
                                    style={{ color: `white` }}
                                  >
                                    {financialDoc.fields.title}
                                  </h2>
                                </div>

                                <p className="small catamaran-16-center grey">
                                  {
                                    financialDoc.fields.description.content[0]
                                      .content[0].value
                                  }
                                </p>
                              </div>
                            </MobileSpacer>
                          </li>
                        </SequentialRender>
                      );
                    })}
                  </ul>

                  <PanUpButton>
                    <MobileButtonWrapper>
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                      <BackButton toPreviousSlide={toPreviousSlide} />
                    </MobileButtonWrapper>

                    <DesktopButtonWrapper>
                      <BackButton toPreviousSlide={toPreviousSlide} />
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                    </DesktopButtonWrapper>
                  </PanUpButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default WhatToBring;
