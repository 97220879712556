import React from "react";
import "../layout.css";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import {
  FadeInLate,
  FadeInLast,
  PanUpFast,
  PanUpButton,
  FadeInBackground,
} from "../animations";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";

const Team = ({
  description = "",
  title = "",
  buttonText = "",
  sectionBackgrounds = "",
  teamList = [],
  nextSlide = "",
  teamDescription,
  slug,
  mobile,

  toNextSlide,
  toPreviousSlide,
  consultants,
  ...props
}) => {
  useScrollToElement("pageTop");
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <section
          id="meet-our-team"
          className="slide-overflow fade-1 kenBurns"
          data-title="Meet our Team"
        >
          <div
            className="content"
            style={{
              overflow: "auto",
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                mobile
                  ? sectionBackgrounds.mobile[slug]
                  : sectionBackgrounds.desktop[slug]
              })`,
            }}
          >
            <div className="container container-div">
              <div className="wrap">
                <div className="fix-10-12 toCenter compensate-margin-top">
                  <PanUpFast>
                    <h1 className="ae-1 calson-44">{title}</h1>
                    <h3 className="fix-10-12 big light ae-2 grey catamaran-20-center">
                      <span>
                        {teamDescription
                          ? teamDescription.content[0].content[0].value
                          : description}
                      </span>
                    </h3>
                  </PanUpFast>
                  <ul className="flex grid-47 catamaran">
                    {teamList.map((advisor, index) => {
                      return (
                        <li key={"team" + index} className="col-6-12">
                          <div
                            style={{
                              minHeight: "500px",
                            }}
                          >
                            <FadeInLate>
                              <img
                                className="ae-3 margin-bottom-2 margin-top-5"
                                style={{ borderRadius: "50%" }}
                                src={advisor.fields.image.fields.file.url}
                                height={150}
                                alt="Step 1"
                              />
                            </FadeInLate>
                            <FadeInLast>
                              <div className="ae-4">
                                <h2
                                  style={{
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {advisor.fields.name}
                                </h2>
                                <h3
                                  style={{
                                    color: "white",
                                    opacity: "0.5",
                                    marginBottom: "5px",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  {advisor.fields.title.toUpperCase()}
                                </h3>
                                <p className="small fix-6-12 catamaran-advisor-description ">
                                  <span>
                                    {
                                      advisor.fields.description.content[0]
                                        .content[0].value
                                    }
                                  </span>
                                </p>
                              </div>
                            </FadeInLast>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  {consultants && consultants.length > 0 && (
                    <>
                      <div style={{ borderTop: "1px solid #8080807d" }}></div>
                      <h1
                        style={{ marginTop: "30px", marginBottom: "unset" }}
                        className="ae-1 calson-44"
                      >
                        Our Consultants
                      </h1>
                      <ul className="flex grid-47 catamaran">
                        {consultants.map((consultant, index) => {
                          return (
                            <li key={"team" + index} className="col-6-12">
                              <div
                                style={{
                                  minHeight: "500px",
                                }}
                              >
                                <FadeInLate>
                                  <img
                                    className="ae-3 margin-bottom-3 margin-top-2"
                                    style={{ borderRadius: "50%" }}
                                    src={
                                      consultant.fields.image.fields.file.url
                                    }
                                    height={150}
                                    alt="Step 1"
                                  />
                                </FadeInLate>
                                <FadeInLast>
                                  <div className="ae-4">
                                    <h2
                                      style={{
                                        marginTop: "0px",
                                        marginBottom: "0px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {consultant.fields.name}
                                    </h2>
                                    <h3
                                      style={{
                                        color: "white",
                                        opacity: "0.5",
                                        marginBottom: "-5px",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {consultant.fields.title.toUpperCase()}
                                    </h3>
                                    <h3
                                      style={{
                                        color: "white",
                                        opacity: "0.5",
                                        marginBottom: "5px",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {consultant.fields.license &&
                                        consultant.fields.license.toUpperCase()}
                                    </h3>
                                    <p className="small fix-6-12 catamaran-advisor-description ">
                                      <span>
                                        {
                                          consultant.fields.description
                                            .content[0].content[0].value
                                        }
                                      </span>
                                    </p>
                                  </div>
                                </FadeInLast>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  <PanUpButton>
                    <MobileButtonWrapper>
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                      <BackButton toPreviousSlide={toPreviousSlide} />
                    </MobileButtonWrapper>

                    <DesktopButtonWrapper>
                      <BackButton toPreviousSlide={toPreviousSlide} />
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                    </DesktopButtonWrapper>
                  </PanUpButton>
                </div>
              </div>
            </div>
          </div>
          <div />
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default Team;
