import React from "react";
import styled from "styled-components";
import "./CustomDocumentList.css";
import { FadeInBackground } from "../animations";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import { PanUpButton } from "../animations";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";
import downloadIcon from "../../assets/img/downloadIcon/download-circle-icon_2x.png";

const HorizontalFlex = styled.div`
  display: flex;
  justify-content: center;

  margin-bottom: 10px;
`;
const Description = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding-left: 5px;
  width: 100%;
  height: 100%;
`;
const Image = styled.img`
  height: 100%;
  width: 90%;
`;

const Content = styled.div`
  width: 250px;
  height: 55px;
  display: flex;
  flex-direction: row;
`;

const CustomDocumentList = ({
  title,
  description,
  mobile,
  nextSlide,
  toNextSlide,
  toPreviousSlide,
  customSlide2Title = "",
  customSlide2Description = "",
  customSlide2Documents = [],
  sectionBackgrounds,
  slug,
}) => {
  useScrollToElement("pageTop");

  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <section className="slide-overflow fade-6 kenBurns">
          <div
            id="custom-documents-list"
            className="content"
            style={{
              overflow: "auto",
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                mobile
                  ? sectionBackgrounds.mobile[slug]
                  : sectionBackgrounds.desktop[slug]
              })`,
            }}
          >
            <div className="container" style={{ height: "100vh" }}>
              <div className="wrap">
                <div className="fix-10-12 compensate-margin-top">
                  <h1 className="ae-1 calson-44">
                    {customSlide2Title && customSlide2Title !== undefined
                      ? customSlide2Title
                      : title}
                  </h1>
                  <p className="ae-2 catamaran-20-center">
                    <span className="opacity-8">
                      {customSlide2Description &&
                      customSlide2Description !== undefined
                        ? customSlide2Description.content[0].content[0].value
                        : description}
                    </span>
                  </p>
                  <ul className="grid later flex equal left grid-65 margin-top-5">
                    {customSlide2Documents instanceof Array
                      ? customSlide2Documents.map((companyDocument, index) => {
                          if (Object.keys(companyDocument).length === 0) {
                            return null;
                          }
                          return (
                            <li
                              key={index}
                              className="col-6-12 ae-6  catamaran-16-center"
                            >
                              <HorizontalFlex>
                                <Content>
                                  <a
                                    href={
                                      companyDocument.fields.download.fields
                                        .file.url
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Image src={downloadIcon} alt="icon" />
                                  </a>

                                  <Description>
                                    {companyDocument.fields.title}
                                  </Description>
                                </Content>
                              </HorizontalFlex>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </div>
                <PanUpButton>
                  <MobileButtonWrapper>
                    <NextSlideButton
                      nextSlide={nextSlide}
                      toNextSlide={toNextSlide}
                    />
                    <BackButton toPreviousSlide={toPreviousSlide} />
                  </MobileButtonWrapper>

                  <DesktopButtonWrapper>
                    <BackButton toPreviousSlide={toPreviousSlide} />
                    <NextSlideButton
                      nextSlide={nextSlide}
                      toNextSlide={toNextSlide}
                    />
                  </DesktopButtonWrapper>
                </PanUpButton>
              </div>
            </div>
          </div>
          <div />
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default CustomDocumentList;
