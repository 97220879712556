import { createClient } from "contentful";

export function getDrinks(meetingId) {
  const drinkSelections = createClient({
    accessToken: `${process.env.GATSBY_SECRET_TOKEN}`,
    space: `${process.env.GATSBY_SPACE_ID}`,
    environment: `${process.env.GATSBY_ENVIRONMENT}`
  });
  return drinkSelections.getEntry(meetingId);
}
