import { createClient } from "contentful";
const getter = createClient({
  accessToken: `${process.env.GATSBY_SECRET_TOKEN}`,
  space: `${process.env.GATSBY_SPACE_ID}`,
  environment: `${process.env.GATSBY_ENVIRONMENT}`,
});
export function getMeeting(meetingId) {
  return getter.getEntry(meetingId);
}

export const getBackground = async (selectedBackground) => {
  const slugs_ids = {},
    ids_title_url = {},
    slugs_titles = {},
    desktop = {},
    title_url = {},
    mobile = {};
    for (var image in selectedBackground) { 
      if (!selectedBackground[image]) {
        delete selectedBackground[image];
      }
    }
  Object.keys(selectedBackground).forEach((slug) => {
    slugs_ids[slug] = selectedBackground[slug].split("/").slice(-3)[0];
  });
  const desktopReq = Object.values(slugs_ids).map((id) => getter.getAsset(id));
  const results = await Promise.all(desktopReq);
  results.forEach((image) => {
    ids_title_url[image.sys.id] = {
      title: image.fields.title,
      url: image.fields.file.url,
    };
  });
  Object.keys(selectedBackground).forEach((slug) => {
    const id = slugs_ids[slug];
    slugs_titles[slug] = ids_title_url[id].title;
  });
  Object.keys(selectedBackground).forEach((slug) => {
    const id = slugs_ids[slug];
    desktop[slug] = ids_title_url[id].url;
  });
  const monbileReq = Object.values(slugs_titles).map((title) =>
    getter.getAssets({ "fields.title": title + "_mobile" })
  );
  const mobileResult = await Promise.all(monbileReq);
  const imagesNames2 = mobileResult.filter(({ items }) => items.length);
  imagesNames2.forEach(({ items }) => {
    const image = items[0];
    title_url[image.fields.title.replace("_mobile", "")] =
      image.fields.file.url;
  });
  Object.keys(selectedBackground).forEach((slug) => {
    if (title_url[slugs_titles[slug]]) {
      mobile[slug] = title_url[slugs_titles[slug]];
    } else {
      mobile[slug] = desktop[slug];
    }
  });
  return({desktop, mobile});
};
