import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const Padding = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
`;

const AspectContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const MobileVideoPlayer = video => {
  return (
    <Padding>
      <AspectContainer>
        <ReactPlayer
          controls={true}
          url={video.video}
          playing={false}
          width="100%"
          height="100%"
        />
      </AspectContainer>
    </Padding>
  );
};

export default MobileVideoPlayer;
