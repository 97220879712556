import React from "react";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import {
  PanLeftFast,
  PanUpButton,
  SequentialRenderLeft,
  FadeInBackground,
} from "../animations";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";

const Testimonials = ({
  sectionBackgrounds,
  nextSlide,
  testimonialList,
  toNextSlide,
  toPreviousSlide,
  slug,
  mobile,

}) => {
  useScrollToElement("pageTop");
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <section
          id="testimonials"
          className="slide-overflow fade-1 kenBurns"
          data-title="Testimonials"
        >
          <div
            className="content"
            style={{
              overflow: "auto",
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                mobile
                  ? sectionBackgrounds.mobile[slug]
                  : sectionBackgrounds.desktop[slug]
              })`,
            }}
          >
            <div className="container" style={{ height: "100vh" }}>
              <div className="wrap">
                <div className="fix-12-12 compensate-margin-top">
                  <PanLeftFast>
                    <ul className="flex grid-47 ">
                      {testimonialList.map((testimonial, index) => {
                        return (
                          <li key={index} className="col-6-12 ae-2 ">
                            <SequentialRenderLeft number={index}>
                              <div
                                style={{
                                  minHeight: "350px",
                                  alignContent: "center",
                                }}
                              >
                                <p className="hero center catamaran-24-center grey">
                                  {
                                    testimonial.fields.description.content[0]
                                      .content[0].value
                                  }
                                </p>
                                <div className="semibold center catamaran-24-center">
                                  <strong>{testimonial.fields.name}</strong>
                                  <br />
                                  <p className="semibold center grey-text catamaran-16-center grey">
                                    {testimonial.fields.subtitle}
                                  </p>
                                </div>
                              </div>
                            </SequentialRenderLeft>
                          </li>
                        );
                      })}
                    </ul>
                  </PanLeftFast>
                  <PanUpButton>
                    <MobileButtonWrapper>
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                      <BackButton toPreviousSlide={toPreviousSlide} />
                    </MobileButtonWrapper>

                    <DesktopButtonWrapper>
                      <BackButton toPreviousSlide={toPreviousSlide} />
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                    </DesktopButtonWrapper>
                  </PanUpButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default Testimonials;
