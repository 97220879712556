import { useEffect, useState, useCallback } from "react";

const MOBILE_BREAKPOINT = 768;

export function useIsMobile(mobileWidth = MOBILE_BREAKPOINT) {
  const isMobile = useCallback(() => {
    if (typeof window === "object") {
      return window.innerWidth < mobileWidth;
    }
    return false;
  }, [mobileWidth]);

  const [mobile, setMobile] = useState(isMobile);

  // Add, remove event listeners
  useEffect(() => {
    if (!(typeof window === "object")) return false;

    function handleResize() {
      if (isMobile !== mobile) {
        setMobile(isMobile);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mobile, isMobile]);

  return mobile;
}
