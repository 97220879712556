const contentful = require("contentful-management");

export async function updateMeetingStatus(id, status) {
  const LOCAL = "en-US";
  const client = contentful.createClient({
    accessToken: `${process.env.GATSBY_SECRET_TOKEN_STORE_ADMIN}`,
  });

  const space = await client.getSpace(`${process.env.GATSBY_SPACE_ID}`);
  const env = await space.getEnvironment(`${process.env.GATSBY_ENVIRONMENT}`);
  const meeting = await env.getEntry(id);
  if (
    meeting.fields.status !== undefined &&
    meeting.fields.status[LOCAL] === "Completed"
  ) {
    return meeting;
  }
  if (meeting.fields.emailSent && meeting.fields.emailSent[LOCAL]) {
    return meeting;
  }
  if (meeting.fields.emailSent === undefined) {
    meeting.fields.emailSent = {};
  }
  meeting.fields.emailSent[LOCAL] = true;
  if (meeting.fields.status === undefined) {
    meeting.fields.status = {};
  }
  meeting.fields.status[LOCAL] = status;
  const newMeeting = await meeting.update();
  return await newMeeting.publish();
}