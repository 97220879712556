import React from "react";
import ReactPlayer from "react-player";
import "../layout.css";
import styled from "styled-components";
import {
  FadeInBackground,
  FadeIn,
  PanUpButton,
  PanUpFast,
} from "../animations";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileVideoPlayer from "../../components/MobileVideoPlayer";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";

const VideoPlayer = styled.div`
  height: "1000px";
  @media (max-width: 600px) {
    height: 100%;
  }
`;

const Padding = styled.div`
  width: "100%";
  margin: "0 auto";
  max-width: "600px";
`;

const Video = ({
  title,
  sectionBackgrounds,
  nextSlide,
  toNextSlide,
  toPreviousSlide,
  video,
  slug,
  mobile,
  ...props
}) => {
  useScrollToElement("pageTop");
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <FadeIn>
          <section className="slide-overflow fade-6 kenBurns" id="video">
            <div
              className="content"
              style={{
                overflow: "auto",
                backgroundPosition: `center`,
                height: `100vh`,
                backgroundImage: `url(${
                  mobile
                    ? sectionBackgrounds.mobile[slug]
                    : sectionBackgrounds.desktop[slug]
                })`,
              }}
            >
              <div className="container" style={{ height: "100vh" }}>
                <div className="wrap">
                  <div className="fix-12-12 white-text compensate-margin-top">
                    <PanUpFast>
                      <h1 className="ae-2 fromLeft calson-44">{title}</h1>
                    </PanUpFast>

                    <div
                      className="test fromCenter margin-top-6 "
                      style={{
                        width: "100%",
                        margin: "0 auto",
                        maxWidth: "600px",
                      }}
                    >
                      <div className="mobile">
                        <MobileVideoPlayer video={video} />
                      </div>
                      <div className="desktop">
                        <VideoPlayer>
                          <ReactPlayer
                            controls={true}
                            url={video}
                            playing={false}
                            width="100%"
                            height="350px"
                            wrapper={Padding}
                          />
                        </VideoPlayer>
                      </div>
                    </div>
                  </div>
                  <PanUpButton>
                    <MobileButtonWrapper>
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                      <BackButton toPreviousSlide={toPreviousSlide} />
                    </MobileButtonWrapper>

                    <DesktopButtonWrapper>
                      <BackButton toPreviousSlide={toPreviousSlide} />
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                    </DesktopButtonWrapper>
                  </PanUpButton>
                </div>
              </div>
            </div>
          </section>
        </FadeIn>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default Video;
