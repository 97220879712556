import React from 'react';

import {
  FadeInBackground,
  PanUpButton,
  PanUpFast,
  PanUpLate,
  PanUp
} from '../animations';

import BackButton from '../../components/BackButton';
import NextSlideButton from '../../components/NextSlideButton';
import MobileButtonWrapper from '../../components/MobileButtonWrapper';
import DesktopButtonWrapper from '../../components/DesktopButtonWrapper';
import { MeetingContext } from '../../context/MeetingProvider/MeetingContext';

const Agenda = ({ nextSlide, toNextSlide, toPreviousSlide }) => {
  const { meetingState } = React.useContext(MeetingContext);

  return (
    <React.Fragment>
      <FadeInBackground>
        <div id='pageTop'></div>
        <section
          className='slide-overflow fade-6 kenBurns'
          id='custom-description'
        >
          <div
            className='content'
            style={{
              overflow: 'auto',
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                meetingState.contentfulMeeting.fields.sectionBackgrounds[
                  'agenda'
                ]
              })`
            }}
          >
            <div className='container' style={{ height: '100vh' }}>
              <div className='wrap'>
                <div className='fix-12-12'>
                  <PanUp>
                    {
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          height: 'auto'
                        }}
                      >
                        <PanUpFast>
                          <h1 className='ae-1 calson-44'>{'Agenda'}</h1>
                          <h3
                            style={{
                              textAlign: 'center',
                              padding: 30,
                              marginBottom: 0,
                              fontFamily: 'Gill Sans Light',
                              fontWeight: 400,
                              fontSize: 24
                            }}
                          >
                            {meetingState.contentfulMeeting.fields.agendaJson[0]
                              .description
                              ? meetingState.contentfulMeeting.fields
                                  .agendaJson[0].description
                              : null}
                          </h3>
                        </PanUpFast>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            width: '70%',
                            height: 'auto',
                            minHeight: '200px',
                            padding: '10px'
                          }}
                        >
                          <PanUpLate>
                            {meetingState.contentfulMeeting.fields.agendaJson[0]
                              .agendaPoints
                              ? meetingState.contentfulMeeting.fields.agendaJson[0].agendaPoints.map(
                                  item => {
                                    return (
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          width: '100%',
                                          height: '100%',
                                          marginBottom: '5px',
                                          minHeight: '40px',
                                          justifyContent: item.timestamp
                                            ? 'flex-start'
                                            : 'center'
                                        }}
                                      >
                                        {item.timestamp ? (
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'flex-start',
                                              justifyContent: 'center',
                                              width: '20%',
                                              fontFamily: 'Gill Sans Light',
                                              fontWeight: 400,
                                              fontSize: 20,
                                              paddingTop: 5
                                            }}
                                          >
                                            {item.timestamp}
                                          </div>
                                        ) : null}

                                        <div
                                          style={{
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'flex-start',
                                            justifyContent: item.timestamp
                                              ? 'flex-start'
                                              : 'center',
                                            width: '80%',
                                            fontFamily: 'Gill Sans Light',
                                            fontWeight: 400,
                                            fontSize: 28
                                          }}
                                        >
                                          {item.talkingPoint}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              : null}
                          </PanUpLate>
                        </div>
                      </div>
                    }
                  </PanUp>
                  <p className='ae-2 catamaran-20-left'>
                    <PanUpButton>
                      <span className='opacity-8'></span>
                    </PanUpButton>
                  </p>

                  <PanUpLate>
                    <MobileButtonWrapper>
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                      <BackButton toPreviousSlide={toPreviousSlide} />
                    </MobileButtonWrapper>

                    <DesktopButtonWrapper>
                      <BackButton toPreviousSlide={toPreviousSlide} />
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                    </DesktopButtonWrapper>
                  </PanUpLate>
                </div>
              </div>
            </div>
          </div>
          <div />
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};
export default Agenda;
