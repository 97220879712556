import React from 'react';
import selectImage from '../../assets/img/financialGoalAssets/Goals_checkmark.png';
import arrow from '../../assets/svg/arrow-down.svg';
import './FinancialGoals.css';
import styled from 'styled-components';
import querystring from 'query-string';
import ReactGA from 'react-ga';
import { useScrollToElement } from '../../assets/js/useScrollToElement';
import {
  PanUpFast,
  PanUp,
  PanUpLateButton,
  SequentialRender,
  FadeInBackground
} from '../animations';
import Image from '../../assets/img/buttonAssets/back-button-slides.png';
import { getGoals } from '../../queries/reads/goalsSelectionOverride';
import { getMeetingId } from '../../queries/helpers/getMeetingId';
import { LoadingContext } from '../../context/LoadingProvider/LoadingContext';
import MobileButtonWrapper from '../../components/MobileButtonWrapper';
import DesktopButtonWrapper from '../../components/DesktopButtonWrapper';

import { writeGoals } from '../../queries/writes/writeGoals';
import { MeetingContext } from '../../context/MeetingProvider/MeetingContext';

ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS);

const SelectableGoal = styled.div`
  background-image: ${props => props.selected && `url(${selectImage})`};
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  min-width: 100%;
  z-index: 10;
`;

const OpacitySetter = styled.div`
  opacity: ${props => (props.selected ? 0.5 : 1)};
  background-color: ${props => (props.selected ? 'white' : 'transparent')};
`;

const FinancialGoals = ({
  nextSlide,
  toNextSlide,
  toPreviousSlide,
  inviteesIndex,
  hreflocation
}) => {
  const [financialSelection, setFinancialSelection] = React.useState([]);
  const { dispatchLoadingState } = React.useContext(LoadingContext);
  const [nodesGoals, setNode2] = React.useState({});
  const { meetingState, dispatchMeetingState } = React.useContext(
    MeetingContext
  );
  const [localFields, setLocalFields] = React.useState({});
  const inviteesObj = meetingState.contentfulMeeting.fields.inviteesObj;
  const firstName = inviteesObj[inviteesIndex].firstName;
  let goalArray = [...financialSelection];

  React.useEffect(() => {
    if (meetingState.contentfulMeeting.fields) {
      const result = meetingState.contentfulMeeting.fields.sections.filter(
        slide => slide.fields.slug === 'financial-goals'
      );
      setLocalFields(result[0].fields);
    }
  }, [meetingState]);

  React.useEffect(() => {
    return () => {
      setFinancialSelection([]);
    };
  }, []);

  const WritetoDb = goalArray => {
    const { preview } = querystring.parse(hreflocation.search);
    if (preview === 'true') {
    } else {
      const ID = getMeetingId();

      dispatchLoadingState(true);
      writeGoals(ID, nodesGoals);

      const updatedMeeting = { ...meetingState.contentfulMeeting };

      if (!updatedMeeting.fields.financialGoalsSelected.nodesGoals[firstName]) {
        updatedMeeting.fields.financialGoalsSelected.nodesGoals[firstName] = [
          goalArray
        ];
      } else {
        updatedMeeting.fields.financialGoalsSelected.nodesGoals[firstName] = [
          goalArray
        ];
      }

      dispatchMeetingState({
        type: 'setMeeting',
        data: updatedMeeting
      });

      dispatchLoadingState(false);
    }
  };

  const capturedSelectionGoals = (item, person) => {
    const collectedNodes = nodesGoals;
    const node = [item];
    collectedNodes[person] = node;
    setNode2(collectedNodes);
  };
  //Used to display the next invitees name at the bottom bar
  let nameArray = [];
  inviteesObj.forEach(function(person, index) {
    if (index !== 0) {
      let string = person.firstName;
      nameArray.push(`${string}'s Goal Selections`);
    }
  });

  // add selected items to an array to be deposited in DB
  const addSelected = goal => {
    if (financialSelection.includes(goal)) {
      goalArray = goalArray.filter(num => num !== goal);
    } else {
      goalArray.push(goal);
    }
    setFinancialSelection(goalArray);
    WritetoDb(goalArray);
  };

  //Used to retrieve Goal choices if they are entering a meeting for a second time so we can repopulate selects
  React.useEffect(() => {
    async function getGoalsChoices() {
      const ID = getMeetingId();
      const incomingMeetingObject = await getGoals(ID);
      if (incomingMeetingObject.fields.financialGoalsSelected === undefined) {
        setFinancialSelection([]);
      } else {
        let incomingSelections =
          incomingMeetingObject.fields.financialGoalsSelected.nodesGoals;
        if (Object.keys(incomingSelections).length === 0) {
          setFinancialSelection([]);
        } else {
          let personsIndex = Object.keys(incomingSelections);
          if (inviteesIndex >= personsIndex.length) {
            setFinancialSelection([]);
          } else {
            personsIndex.forEach(function(person) {
              if (person === firstName) {
                setFinancialSelection(incomingSelections[person][0]);
              } else {
              }
            });
          }
        }
      }
    }
    getGoalsChoices();
  }, []);

  // Google analytics event to track the drink choices
  React.useEffect(() => {
    return () => {
      const { preview } = querystring.parse(hreflocation.search);
      if (preview !== 'true') {
        if (goalArray !== null) {
          ReactGA.event({
            category: 'Financial Goal',
            action: `Client selected a goal`
          });
        }
      }
    };
  }, [goalArray]);

  useScrollToElement('pageTop');
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id='pageTop'></div>
        <section
          id='financial-goals'
          className='slide-overflow fade-3 kenBurns'
          data-title='Financial Goals'
        >
          <div
            className='content container-div'
            style={{
              overflow: 'auto',
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                meetingState.contentfulMeeting.fields.sectionBackgrounds[
                  'financial-goals'
                ]
              })`
            }}
          >
            <div className='container' style={{ height: '100vh' }}>
              <div className='wrap'>
                <PanUpFast>
                  <div className='fix-10-12 toCenter compensate-margin-top'>
                    <h1 className='ae-1 calson-44'>{localFields.title}</h1>
                    <p className='ae-2'>
                      <span className='normal catamaran-24-center'>
                        <u>
                          <b>{firstName}</b>
                        </u>
                        {`, ${localFields.description}`}
                      </span>
                    </p>
                  </div>
                </PanUpFast>
                <PanUp>
                  <div className='fix-12-12'>
                    <ul
                      className='flex  fixedSpaces grid-69 equal equalMobile popupTrigger controller financial-goals'
                      data-popup-id='69-4'
                      data-slider-id='69-4'
                    >
                      {meetingState.contentfulMeeting.fields.financialGoals.map(
                        (card, index) => {
                          if (card.fields.image) {
                            var style;

                            style = {
                              backgroundImage: `url(${card.fields.image.fields.file.url})`,
                              color: `${
                                card.fields.title === 'Big Purchase'
                                  ? 'white'
                                  : '#202020 '
                              }`,
                              position: `relative`,
                              zIndex: `0`
                            };
                          } else {
                            style = {};
                          }
                          return (
                            <li
                              key={'goal' + index}
                              className='col-4-12 col-tablet-1-2 col-phablet-1-1'
                              style={{
                                alignItems: 'center'
                              }}
                            >
                              <SequentialRender number={index} selected={false}>
                                <SelectableGoal
                                  selected={financialSelection.includes(
                                    card.fields.title
                                  )}
                                >
                                  <OpacitySetter
                                    selected={financialSelection.includes(
                                      card.fields.title
                                    )}
                                  >
                                    <div
                                      className='box-69 rounded ae-8 equalElement financial-goal'
                                      style={style}
                                      onClick={() => {
                                        addSelected(card.fields.title);
                                        capturedSelectionGoals(
                                          goalArray,
                                          inviteesObj[inviteesIndex].firstName
                                        );
                                      }}
                                    >
                                      <h6
                                        className='margin-bottom-1 catamaran-22-center'
                                        style={{
                                          marginTop: '14%'
                                        }}
                                      >
                                        {card.fields.title}
                                      </h6>
                                      <div className='table cell-69 equalElement'>
                                        <div className='cell'>
                                          <div
                                            className='button round  Chrish crop margin-top-5 ae-10 catamaran-button'
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            SELECT
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </OpacitySetter>
                                </SelectableGoal>
                              </SequentialRender>
                            </li>
                          );
                        }
                      )}
                    </ul>
                    <PanUpLateButton>
                      <MobileButtonWrapper>
                        <div
                          className='button nextSlide round white stroke crop margin-top-5 ae-10 catamaran-button'
                          onClick={() => toNextSlide()}
                        >
                          {nameArray[inviteesIndex] !== undefined
                            ? nameArray[inviteesIndex].toUpperCase()
                            : nextSlide.toUpperCase()}

                          <i className='material-icons'></i>
                          <img className='arrow' src={arrow} alt='test' />
                        </div>
                        <div
                          style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: ' cover',
                            width: '60px',
                            height: '60px',
                            marginRight: '12px'
                          }}
                          className='button nextSlide round margin-top-5 ae-10 catamaran-button'
                          onClick={() => {
                            toPreviousSlide();
                          }}
                        ></div>
                      </MobileButtonWrapper>
                      <DesktopButtonWrapper>
                        <div
                          style={{
                            backgroundImage: `url(${Image})`,
                            backgroundSize: ' cover',
                            width: '60px',
                            height: '60px',
                            marginRight: '12px'
                          }}
                          className='button nextSlide round margin-top-5 ae-10 catamaran-button'
                          onClick={() => {
                            toPreviousSlide();
                          }}
                        ></div>
                        <div
                          className='button nextSlide round white stroke crop margin-top-5 ae-10 catamaran-button'
                          onClick={() => toNextSlide()}
                        >
                          {nameArray[inviteesIndex] !== undefined
                            ? nameArray[inviteesIndex].toUpperCase()
                            : nextSlide.toUpperCase()}

                          <i className='material-icons'></i>
                          <img className='arrow' src={arrow} alt='test' />
                        </div>
                      </DesktopButtonWrapper>
                    </PanUpLateButton>
                  </div>
                </PanUp>
              </div>
            </div>
          </div>
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default FinancialGoals;
