import React from "react";
import "./CustomDescription.css";
import {
  FadeInBackground,
  PanUpButton,
  PanUpFast,
  PanUpLate,
  PanUp,
} from "../animations";
import { useScrollToElement } from "../../assets/js/useScrollToElement";
import BackButton from "../../components/BackButton";
import NextSlideButton from "../../components/NextSlideButton";
import MobileButtonWrapper from "../../components/MobileButtonWrapper";
import DesktopButtonWrapper from "../../components/DesktopButtonWrapper";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const CustomDescription = ({
  slug,
  description,
  sectionBackgrounds,
  title,
  mobile,
  subtitle,
  nextSlide,
  toNextSlide,
  toPreviousSlide,
  customSlide1Title,
  customSlide1Subtitle,
  customSlide1Description,
  ...props
}) => {
  useScrollToElement("pageTop");
  return (
    <React.Fragment>
      <FadeInBackground>
        <div id="pageTop"></div>
        <section
          className="slide-overflow fade-6 kenBurns"
          id="custom-description"
        >
          <div
            className="content"
            style={{
              overflow: "auto",
              backgroundPosition: `center`,
              height: `100vh`,
              backgroundImage: `url(${
                mobile
                  ? sectionBackgrounds.mobile[slug]
                  : sectionBackgrounds.desktop[slug]
              })`,
            }}
          >
            <div className="container" style={{ height: "100vh" }}>
              <div className="wrap">
                <div
                  className="FixedCustoms"
                  style={{
                    margin: "0 auto",

                    maxWidth: "775px",
                  }}
                >
                  <div className="fix-12-12 compensate-margin-top">
                    <PanUpFast>
                      <h1 className="ae-1 calson-44">
                        {customSlide1Title ? customSlide1Title : title}
                      </h1>
                    </PanUpFast>
                    <PanUp>
                      <h2 className="catamaran-24-center">
                        {customSlide1Subtitle ? customSlide1Subtitle : subtitle}
                      </h2>
                      <p className="ae-2 catamaran-20-left">
                        <PanUpButton>
                          <span
                            className="opacity-8"
                            dangerouslySetInnerHTML={{
                              __html:
                                customSlide1Description &&
                                customSlide1Description !== undefined
                                  ? documentToHtmlString(
                                      customSlide1Description
                                    )
                                  : "",
                            }}
                          ></span>
                        </PanUpButton>
                      </p>
                    </PanUp>
                  </div>

                  <PanUpLate>
                    <MobileButtonWrapper>
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                      <BackButton toPreviousSlide={toPreviousSlide} />
                    </MobileButtonWrapper>

                    <DesktopButtonWrapper>
                      <BackButton toPreviousSlide={toPreviousSlide} />
                      <NextSlideButton
                        nextSlide={nextSlide}
                        toNextSlide={toNextSlide}
                      />
                    </DesktopButtonWrapper>
                  </PanUpLate>
                </div>
              </div>
            </div>
          </div>
          <div />
        </section>
      </FadeInBackground>
    </React.Fragment>
  );
};

export default CustomDescription;
