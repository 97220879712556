import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import styled from "styled-components";

export const MapContainerMobile = ({ latitude, longitude, google }) => {
  const Padding = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    position: relative;
  `;

  const AspectContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `;
  return (
    <Padding>
      <AspectContainer>
        <Map
          google={google}
          zoom={15}
          initialCenter={{ lat: latitude, lng: longitude }}
        >
          <Marker name={"test"} position={{ lat: latitude, lng: longitude }} />
        </Map>
      </AspectContainer>
    </Padding>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY
})(MapContainerMobile);
