import React from 'react';
import styled from 'styled-components';
import './summary.css';
import MapContainer from '../../components/MapContainer';
import { useScrollToElement } from '../../assets/js/useScrollToElement';
import { PanUp, PanLeftFast, FadeIn, FadeInBackground } from '../animations';
import { updateMeetingStatus } from '../../queries/writes/writeMeetingStatus';
import emailVariables from '../../constants/emailVariables';
import querystring from 'query-string';
import MapContainerMobile from '../../components/MapContainerMobile';
import { LoadingContext } from '../../context/LoadingProvider';
import { MeetingContext } from '../../context/MeetingProvider/MeetingContext';
import { EmailContext } from '../../../src/context/EmailProvider';

const axios = require('axios');

const ButtonsContainerWrapper = styled.div`
  border-bottom: 1px solid rgb(216, 216, 216, 0.25);
  width: 100%;
  height: 1px;
`;

const Summary = ({
  sectionBackgrounds,
  title,
  subtitle,
  note,
  location,
  datetime,
  invitees,
  downloadPdf,
  province,
  city,
  postalCode,
  website,
  hreflocation,
  slug,
  advisorType,
  emailLogo,
  phoneInternet,
  selectedLocation,
  advisorSpecifics,
  mobile,
  meetingStatus,
  isEmailSent
  //...advisor
}) => {
  const [summary, setSummary] = React.useState('');
  const [done, setDone] = React.useState(false);
  const { loadingState } = React.useContext(LoadingContext);
  const { meetingState } = React.useContext(MeetingContext);
  const { emailState, dispatchEmailState } = React.useContext(EmailContext);
  const advisor = meetingState.contentfulAdvisor.fields;

  const teamLogo =
    emailLogo.fields !== undefined ? emailLogo.fields.file.url : '';

  let isMfda = false;
  if (advisorType === 'MFDA') {
    isMfda = true;
  }

  const getMeetingDetails = meetingLocation => {
    switch (meetingLocation) {
      case 'phone':
      case undefined:
        return {
          location: meetingLocation,
          ...location,
          googleApisQuery: `${advisor.address}+${city}+${province}+${postalCode}`,
          details: phoneInternet,
          address: `${advisor.address}, ${city}, ${province}, ${postalCode}`,
          addressTitle: 'Address',
          phone: advisor.phone
        };
      case 'main':
        return {
          location: meetingLocation,
          ...location,
          googleApisQuery: `${advisor.address}+${city}+${province}+${postalCode}`,
          details: '',
          address: `${advisor.address}, ${city}, ${province}, ${postalCode}`,
          addressTitle: 'Address',
          phone: advisor.phone
        };
      case 'others':
        return {
          location: meetingLocation,
          ...location,
          googleApisQuery: `${selectedLocation.address}+${selectedLocation.city}+${selectedLocation.province}+${selectedLocation.postalCode}`,
          details: `${selectedLocation.officeTitle}, ${selectedLocation.address}, ${selectedLocation.city}, ${selectedLocation.province} ${selectedLocation.postalCode}`,
          address: `${advisor.address}, ${city}, ${province}, ${postalCode}`,
          addressTitle: 'Address',
          phone: advisor.phone
        };
      default:
        const { fields: address } =
          advisorSpecifics &&
          advisorSpecifics.addresses.find(
            address => address.sys.id === meetingLocation
          );
        return {
          location: meetingLocation,
          ...address.location,
          googleApisQuery: `${address.address}+${address.city}+${address.province}+${address.postalCode}`,
          details: '',
          address: `${address.address}, ${address.city}, ${address.province}, ${address.postalCode}`,
          addressTitle: 'Meeting Address',
          phone: address.phone
        };
    }
  };
  const MeetingDetails = getMeetingDetails(selectedLocation.location);
  // tidies up frontend
  const saveDate = new Date(datetime);
  let year = saveDate.getFullYear();
  let month = saveDate.getMonth() + 1;
  let day = saveDate.getDate();
  let pretime = saveDate.getHours();
  let minutes = saveDate.getMinutes();
  let alteredTime = pretime;
  let am = 'am';
  let pm = 'pm';

  switch (minutes) {
    case 0:
      minutes = '00';
      break;
    case 1:
      minutes = '01';
      break;
    case 2:
      minutes = '02';
      break;
    case 3:
      minutes = '03';
      break;
    case 4:
      minutes = '04';
      break;
    case 5:
      minutes = '05';
      break;
    case 6:
      minutes = '06';
      break;
    case 7:
      minutes = '07';
      break;
    case 8:
      minutes = '08';
      break;
    case 9:
      minutes = '09';
      break;
    default:
      break;
  }

  switch (month) {
    case 1:
      month = 'January';
      break;
    case 2:
      month = 'February';
      break;
    case 3:
      month = 'March';
      break;
    case 4:
      month = 'April';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'June';
      break;
    case 7:
      month = 'July';
      break;
    case 8:
      month = 'August';
      break;
    case 9:
      month = 'September';
      break;
    case 10:
      month = 'October';
      break;
    case 11:
      month = 'November';
      break;
    case 12:
      month = 'December';
      break;
    default:
      break;
  }

  let time = `${pretime}:${minutes}`;
  switch (alteredTime) {
    case 12:
      pretime = '12';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 13:
      pretime = '1';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 14:
      pretime = '2';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 15:
      pretime = '3';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 16:
      pretime = '4';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 17:
      pretime = '5';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 18:
      pretime = '6';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 19:
      pretime = '7';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 20:
      pretime = '8';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 21:
      pretime = '9';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 22:
      pretime = '10';
      time = `${pretime}:${minutes}${pm}`;
      break;
    case 23:
      pretime = '11';
      time = `${pretime}:${minutes}${pm}`;
      break;
    default:
      time = `${pretime}:${minutes}${am}`;
      break;
  }

  let concatenatedSummaryString = '';
  React.useEffect(() => {
    if (!done) {
      const constructSummary = () => {
        const { meetingId } = querystring.parse(hreflocation.search);
        const { preview } = querystring.parse(hreflocation.search);
        getStringReady(invitees).then(() => {
          if (
            summary &&
            !preview &&
            !emailState.emailSent &&
            !isEmailSent &&
            meetingStatus !== 'Complated'
          ) {
            sendEmail(concatenatedSummaryString);
            dispatchEmailState(true);
          }
        });
      };
      if (!loadingState.IsLoading) {
        constructSummary();
      }
    }
  }, [loadingState.IsLoading, summary, done]);

  // Creates the object we use to build an email for clients
  async function sendEmail(summary) {
    const item = { name: 'Advisor', email: advisor.email };
    let inviteesArray = [item];
    invitees.forEach(function(person) {
      let name = person.firstName;
      let email = person.email;

      inviteesArray.push({ name: name, email: email });
    });
    let emails = [];
    inviteesArray.forEach(({ email }) => {
      emails.push(email);
    });
    const emailObject = {
      to: emails.filter(emails => emails !== advisor.email),
      from: {
        email: emailVariables.from,
        name: advisor.advisorTeamName
      },
      cc: advisor.email,
      templateId:
        advisorType === 'OBA'
          ? emailVariables.templateIdOBA
          : emailVariables.templateId,
      dynamicTemplateData: {
        inviteeName: invitees[0].name,
        advisorEmail: advisor.email,
        advisorPhone: MeetingDetails.phone,
        advisorName: advisor.name,
        advisorAddress: MeetingDetails.address,
        advisorWebsite: website,
        advisorDirections:
          selectedLocation.location !== 'others' &&
          selectedLocation.location !== 'phone'
            ? `https://www.google.com/maps/search/?api=1&query=${MeetingDetails.googleApisQuery}`
            : '',
        advisorLogo1: isMfda,
        advisorLogo2: `https:${teamLogo}`,
        privacyLink: `https://www.ipcc.ca/privacy`,
        legalLink: `https://www.ipcc.ca/legal`,
        accessibilityLink: `https://www.ipcc.ca/accessibility`,
        codeOfEthicsLink: `https://www.ipcc.ca/code-of-ethics`,
        meetingSummary: `We look forward to our meeting with you on ${month} ${day}, ${year} at ${time}. ${summary}`,
        meetingNote: note,
        meetingPdfLink:
          downloadPdf.fields !== undefined
            ? `https:${downloadPdf.fields.file.url}`
            : '',
        details: MeetingDetails.details
          .split('<p>')
          .join(`<p style="font-size:18px">`),
        hasdetails:
          selectedLocation.location === 'phone' ||
          selectedLocation.location === 'others'
      }
    };
    axios({
      method: 'post',
      url: process.env.GATSBY_SENDGRID_ENDPOINT,
      data: emailObject
    }).catch(error => console.log(error));
  }

  React.useEffect(() => {
    async function setMeetingToComplete() {
      const { meetingId } = querystring.parse(hreflocation.search);
      await updateMeetingStatus(meetingId, 'Completed');
    }

    const { preview } = querystring.parse(hreflocation.search);
    if (preview !== 'true' && loadingState.IsLoading === false) {
      setMeetingToComplete();
    }
  }, [loadingState.IsLoading]);

  const getMemberString = async person => {
    const nodesGoals = meetingState.contentfulMeeting.fields
      .financialGoalsSelected
      ? meetingState.contentfulMeeting.fields.financialGoalsSelected.nodesGoals
      : {};

    const nodesDrinks = meetingState.contentfulMeeting.fields.drinksSelected
      .nodesDrinks
      ? meetingState.contentfulMeeting.fields.drinksSelected.nodesDrinks
      : {};

    const name = person.firstName;
    let drink = nodesDrinks[name];
    let goals = nodesGoals[name];
    let personString = '';

    if (
      drink !== undefined &&
      drink[0] === 'No. Thank-You' &&
      (goals === undefined || !goals[0].length)
    ) {
      personString = `${name}, we will be ready to discuss any of your financial needs. `;
      return personString;
    } else {
      if (drink === undefined || !drink[0].length) {
        personString = personString + '';
      } else {
        switch (drink[0]) {
          case 'No. Thank-You':
            personString = personString + `${name}, `;
            break;

          case "I'll be spontaneous":
            personString =
              personString + `${name}, we will have a drink for you`;
            break;
          default:
            personString =
              personString + `${name}, we will have a ${drink} for you`;
            break;
        }
      }
      if (goals === undefined || !goals[0].length) {
        if (drink === undefined || !drink[0].length) {
          personString = '';
        } else {
          personString =
            personString +
            ` and we will be ready to discuss any of your financial needs. `;
        }
      } else {
        if (drink === undefined || !drink[0].length) {
          personString = personString + `${name}, `;
        } else {
          switch (drink[0]) {
            case 'No. Thank-You':
              personString = personString + ` `;
              break;
            case "I'll be spontaneous":
              personString = personString + ` and `;
              break;
            default:
              personString = personString + ` and `;
              break;
          }
        }
        let newGoal = '';
        goals[0].forEach(function(uniqueGoal) {
          newGoal = `${newGoal} ${uniqueGoal},`;
        });
        personString =
          personString +
          `we will discuss ${[
            newGoal
          ]}  and any other of your financial needs. `;
      }
      return personString;
    }
  };
  useScrollToElement('pageTop');
  const getStringReady = async invitees => {
    for (const person of invitees) {
      const finalString = await getMemberString(person);
      concatenatedSummaryString = concatenatedSummaryString + ` ${finalString}`;
    }
    setSummary(concatenatedSummaryString);
    setDone(true);
  };

  return (
    <React.Fragment>
      {loadingState.IsLoading ? (
        <div></div>
      ) : (
        <FadeInBackground>
          <div id='pageTop'></div>
          <section
            id='summary'
            className='slide-overflow fade-3 kenBurns'
            data-title='Summary'
          >
            <div
              className='content'
              style={{
                overflow: 'auto',
                backgroundPosition: `center`,
                height: `100vh`,
                backgroundImage: `url(${
                  mobile
                    ? sectionBackgrounds.mobile[slug]
                    : sectionBackgrounds.desktop[slug]
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%'
              }}
            >
              <div className='container container-div'>
                <div className='wrap'>
                  <div className='fix-12-12'>
                    <div className='flex verticalCenter later reverse'>
                      <div className='col-6-12 left compensate-margin-top'>
                        <PanLeftFast>
                          <h1 className='ae-1 fromLeft calson-44'>{title}</h1>
                          {MeetingDetails.location !== 'others' &&
                            MeetingDetails.location !== 'phone' && (
                              <PanUp>
                                <div
                                  className='mobile'
                                  style={{ paddingBottom: `30px` }}
                                >
                                  <MapContainerMobile
                                    latitude={location.lat}
                                    longitude={location.lon}
                                  />
                                </div>
                              </PanUp>
                            )}
                          <p className='normal summarydesc white-text catamaran-20-left'>
                            {`We look forward to our meeting with you `}
                            on {month} {day}, {year} at {time}. {summary}
                            <br />
                            <strong className='catamaran-24-center'>
                              {subtitle}
                            </strong>
                          </p>
                        </PanLeftFast>
                        <br />
                        {MeetingDetails.details && (
                          <div className='meetingDetails'>
                            <h2
                              style={{ fontSize: '32px' }}
                              className='ae-1 fromLeft calson-44'
                            >
                              Meeting Details
                            </h2>
                            <div
                              className='normal semiBold catamaran-24-left'
                              style={{ fontSize: '18px' }}
                              dangerouslySetInnerHTML={{
                                __html: MeetingDetails.details
                              }}
                            />
                          </div>
                        )}
                        <h2
                          style={{ fontSize: '32px', marginTop: '30px' }}
                          className='ae-1 fromLeft calson-44'
                        >
                          Office Contact
                        </h2>
                        <h3 className='normal uppercase opacity-6 grey-text catamaran-16-left'>
                          E-mail
                        </h3>
                        <h2 className='normal semiBold catamaran-24-left'>
                          {advisor.email}
                        </h2>
                        <h3 className='normal uppercase opacity-6 grey-text catamaran-16-left'>
                          Phone
                        </h3>
                        <h2 className='normal semiBold catamaran-24-left'>
                          <h2 className='normal semiBold white-text catamaran-24-left '>
                            {MeetingDetails.phone}
                          </h2>
                        </h2>
                        <h3 className='normal uppercase opacity-6 grey-text catamaran-16-left'>
                          {MeetingDetails.addressTitle}
                        </h3>
                        <h2 className='normal semiBold white-text catamaran-24-left '>
                          {MeetingDetails.address}
                        </h2>
                        {!!note && (
                          <div className='summary-footer catamaran-18-left'>
                            <p>{note}</p>
                          </div>
                        )}

                        <FadeIn>
                          <div className='buttons-container'>
                            <a
                              className='button round white ae-3 stroke crop catamaran-button'
                              href={website}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              our site
                            </a>
                            {MeetingDetails.location !== 'others' &&
                              MeetingDetails.location !== 'phone' && (
                                <a
                                  className='margin-left-1  button round white ae-3 fromCenter crop catamaran-button'
                                  href={`https://www.google.com/maps/search/?api=1&query=${MeetingDetails.googleApisQuery}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  Directions
                                </a>
                              )}
                            {downloadPdf &&
                              downloadPdf.fields &&
                              downloadPdf.fields.file.url && (
                                <a
                                  className='margin-left-1  button round white  ae-3 fromCenter crop catamaran-button'
                                  href={downloadPdf.fields.file.url}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  additional info
                                </a>
                              )}
                          </div>
                          {advisorType === 'OBA' ? (
                            <div />
                          ) : (
                            <ButtonsContainerWrapper />
                          )}
                          {advisorType === 'OBA' ? (
                            <div />
                          ) : (
                            <div className='tags-container'>
                              <ul className='menu toleft uppercase catamaran'>
                                <li>
                                  <a
                                    href='https://www.ipcc.ca/privacy'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    Privacy
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href='https://www.ipcc.ca/legal'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    Legal
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href='https://www.ipcc.ca/accessibility'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    Accessibility
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href='https://www.ipcc.ca/code-of-ethics'
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    Code of Ethics
                                  </a>
                                </li>
                              </ul>
                            </div>
                          )}
                        </FadeIn>
                      </div>

                      <div className='col-6-12 right '>
                        <PanUp>
                          <div
                            className='desktop'
                            style={{ marginLeft: '150px', marginTop: '-80px' }}
                          >
                            <MapContainer
                              latitude={MeetingDetails.lat}
                              longitude={MeetingDetails.lon}
                              location={MeetingDetails.location}
                            />
                          </div>
                        </PanUp>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div />
          </section>
        </FadeInBackground>
      )}
    </React.Fragment>
  );
};

export default Summary;
