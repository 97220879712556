import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  @media screen and (min-width: 603px) {
    display: none;
  }
`;

export const MobileButtonWrapper = ({ children }) => {
  return (
    <React.Fragment>
      <Wrap>{children}</Wrap>
    </React.Fragment>
  );
};

export default MobileButtonWrapper;
