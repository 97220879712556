import { createClient } from "contentful";

function getAdvisorTeam(advisorId) {
  var contentfulClient = createClient({
    accessToken: `${process.env.GATSBY_SECRET_TOKEN}`,
    space: `${process.env.GATSBY_SPACE_ID}`,
    environment: `${process.env.GATSBY_ENVIRONMENT}`
  });

  return contentfulClient.getEntries({
    content_type: "users",
    "sys.id": advisorId,
    include: 2,
    limit: 1
  });
}

export { getAdvisorTeam };
