import styled, { keyframes } from 'styled-components';
import {
  fadeInUp,
  fadeInRight,
  fadeInDown,
  fadeInLeft,
  fadeIn
} from 'react-animations';

const fadeAnimationIn = keyframes`${fadeIn}`;
const fadeAnimationUp = keyframes`${fadeInUp}`;
const fadeAnimationRight = keyframes`${fadeInRight}`;
const fadeAnimationLeft = keyframes`${fadeInLeft}`;
const fadeAnimationDown = keyframes`${fadeInDown}`;

const SequentialRender = styled.div`
  animation: 3s ${fadeAnimationUp};
  animation-delay: ${props => props.number / 3}s;
  height: 100%;
  animation-fill-mode: backwards;
`;
const SequentialRenderLeft = styled.div`
  animation: 3s ${fadeAnimationLeft};
  animation-delay: ${props => props.number / 2}s;
  height: 100%;
  animation-fill-mode: backwards;
`;

const Zoom = styled.div`
  animation: 2s ${fadeAnimationUp};
  height: 100%;
`;

const FadeIn = styled.div`
  animation-delay: 1s;
  animation: 4s ${fadeAnimationIn};
  height: 100%;
`;

const FadeInBackground = styled.div`
  animation-delay: 0s;
  animation: 1.5s ${fadeAnimationIn};
  height: 100%;
`;

const FadeInLate = styled.div`
  animation-delay: 3s;
  animation: 5s ${fadeAnimationIn};
  height: 100%;
`;

const FadeInLast = styled.div`
  animation-delay: 4s;
  animation: 2s ${fadeAnimationUp};
  height: 100%;
`;

const PanDown = styled.div`
  animation: 1s ${fadeAnimationDown};
  animation-delay: 2s;
  animation-fill-mode: backwards;
`;

const PanRight = styled.div`
  animation: 2s ${fadeAnimationRight};
  animation-delay: 2s;
  animation-fill-mode: backwards;
`;

const PanRightFast = styled.div`
  animation: 1.5s ${fadeAnimationRight};
  animation-delay: 0s;
  animation-fill-mode: backwards;
`;

const PanLeft = styled.div`
  animation: 1s ${fadeAnimationLeft};
  animation-delay: 2s;
  animation-fill-mode: backwards;
`;

const PanLeftFast = styled.div`
  animation: 1s ${fadeAnimationLeft};
  animation-delay: 0s;
  animation-fill-mode: backwards;
`;

const PanUpLate = styled.div`
  animation: 1s ${fadeAnimationUp};
  animation-delay: 3s;
  animation-fill-mode: backwards;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const PanUpButton = styled.div`
  animation: 1s ${fadeAnimationUp};
  animation-delay: 2s;
  animation-fill-mode: backwards;
`;

const PanUpLateButton = styled.div`
  animation: 1s ${fadeAnimationUp};
  animation-delay: 4s;
  animation-fill-mode: backwards;
`;

const PanUp = styled.div`
  animation: 2s ${fadeAnimationUp};
  animation-delay: 1;
  animation-fill-mode: backwards;
`;

const PanUpFast = styled.div`
  animation: 1s ${fadeAnimationUp};
  animation-delay: 2;
  animation-fill-mode: backwards;
`;

export {
  SequentialRender,
  SequentialRenderLeft,
  Zoom,
  FadeIn,
  FadeInLate,
  FadeInLast,
  FadeInBackground,
  PanRight,
  PanRightFast,
  PanLeft,
  PanLeftFast,
  PanUpLate,
  PanUp,
  PanUpFast,
  PanUpButton,
  PanUpLateButton,
  PanDown
};
